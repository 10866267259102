import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import OFOSearch from '../../program/OFOSearch';
import InstitutionSearch from '../../program/InstitutionSearch';
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerBSPBursaryForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [dependency, setDependency] = React.useState(props.dependency);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    function GetProgrammeStatusID(value) {
        //console.clear();
        //console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function GetBursaryTypeID(value) {
        //console.clear();
        //console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('IsVerified', event.target.checked);
    };

    const handleReplacementChange2 = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('IsQualificationCompleted', event.target.checked);
    };

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function BursaryTypeCheckOld(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employer" || value.Description === "Bursary Employed") {
                return true
            }
            else {
                return false
            }
        }
    }

    function EmployedCheck(input: string): boolean {
        if (input.includes("Employer") || input.includes("Bursary Employed")) {
            return true
        }
        else {
            return false
        }
    }

    function BursaryTypeCheck(value) {
        if (value !== undefined && value !== null) {
            return EmployedCheck(value.Description);
        }
        return false
    }

    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }


    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "HWSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }


    //function nqfchange(form) {
    //    if (props.dependencyHander != null) {
    //        form.values['NQFLevelID'] = props.dependencyHander;
    //    }
    //}

    return (
        <EntityForm ref={ref} values={props.data} viewId="BursarySetup" dataURL="api/sims/learnerbspbursary/GetLearnerLinkedBursaryDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerBursaryForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Trade Union Officials Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Placement" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ContractNumber"
                                                key={tabValue}
                                                label="Contract Number"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerbspbursary/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetSponsorshipForBursary"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Funder"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/learnerbspbursary/GetProjectByInterventionType?parameter=[{"Name":"InterventionType","Value":' + props.BSPType + '}]'}
                                                id="ProjectID"
                                                label="Project"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                allowEditEmptyValue={true} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ServiceLevelAgreementNumber"
                                                key={tabValue}
                                                label="Learner Agreement Number"
                                                className="w-100 "
                                                disabled={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                label="Financial Year"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=InstitutionType"
                                                id="InstitutionTypeID"
                                                label="Institution Type"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="Institution"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerbspbursary/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CostPerLearner"
                                                key={tabValue}
                                                label="Cost Per Learner"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="BursaryQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerbspbursary/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("BursaryQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=BursaryType"
                                                id="BursaryTypeID"
                                                label="Bursary Type"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                //onChange={nqfchange(form)}
                                                onChange={props.onChangeBursaryTypeIDHandler}
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required={BursaryTypeCheck(form.values["BursaryTypeID"])}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl='api/sims/learnerbspbursary/EmployerSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistration"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Most Recent Registration"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="BursaryStartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Actual Start Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="BursaryEndDate"
                                                validationGroup="registrationDates"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.BursaryStartDate)
                                                            && formValues.BursaryStartDate.getTime() < formValues.BursaryEndDate.getTime()
                                                    }, message: "Actual Start date must be before the end date"
                                                }}
                                                key={tabValue}
                                                label="End Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                //dataUrl="api/sims/learnerbursary/GetLookupListNQFLevel?parameter="
                                                dataUrl={'api/sims/learnerbspbursary/GetLookupListNQFLevel?parameter=[{"Name":"BursaryTypeID","Value":' + GetBursaryTypeID(form.values["BursaryTypeID"]) + '}]'}
                                                id="NQFLevelID"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="NQF Level"
                                                className="{classes.select} w-100"
                                                form={form}
                                                //hasDependency={true}
                                                //dependency={dependency}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=BursaryYearOfStudy"
                                                id="BursaryYearOfStudyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Academic Year Of Study"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=BursaryProviderClass"
                                                id="BursaryProviderClassID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Bursary Provider Class"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FundingAgreementNumber"
                                                key={tabValue}
                                                label="Funding Agreement Number"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CumulativeSpent"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Amount Spent Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsVerified"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                onChange={handleReplacementChange}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Information Verified"
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Verifier"
                                                key={tabValue}
                                                label="Verifier"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsQualificationCompleted"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                onChange={handleReplacementChange2}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Qualification Completed"
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="LastSignatureDate"
                                                key={tabValue}
                                                label="Last Signature Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionProcessedDate"
                                                key={tabValue}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="VerifiedDate"
                                                        key={tabValue}
                                                        label="Verifier Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["BursaryStatusID"])}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["BursaryStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["BursaryStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminatedDate"
                                                        key={tabValue}
                                                        label="Actual Terminated Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnerbspbursary/GetProgrammeStatusByRole?parameter=[{"Name":"BursaryStatusID","Value":' + GetProgrammeStatusID(form.values["BursaryStatusID"]) + '}]'}
                                                        id="BursaryStatusID"
                                                        label="Bursary Status"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        required />
                                                </td>
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerbspbursary/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        className="{classes.select} w-100"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CapturedByUser"
                                                        key={tabValue}
                                                        label="Captured By User"
                                                        disabled
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        disabled
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        disabled
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <tr>
                                <td>
                                    <GPSCoordinatesAPI
                                        id="Address"
                                        key={tabValue}
                                        className="w-100"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Address"
                                        form={form}
                                        updateGPSAddress={updateGPSAddress}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress1"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 1"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress2"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 2"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress3"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        label="Employer Physical Address 3"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <AddressCodeItem
                                        id="PhysicalCode"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        addressId="Physical"
                                        value={physicalCodeValue}
                                        key={tabValue}
                                        provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                        municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                        districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                        urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                        suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                        cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                        className="w-100"
                                        label="Employer Physical Code"
                                        hasMunicipalityID={true}
                                        hasProvinceID={true}
                                        hasCityID={true}
                                        hasDistrictID={false}
                                        hasUrbanRuralID={true}
                                        hasSuburbID={true}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentStartDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employment Start Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
