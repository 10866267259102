import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ViewUtils from "../ViewUtils";
import AlertItem from "../widgets/AlertItem";

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const [title, setTitle] = React.useState(props.dialogTitle);
    const [Message, setMessage] = React.useState("");
    const [MessageStyle, setMessageStyle] = React.useState("");
    const [values, setValues] = React.useState(null);
    const [isView, setIsView] = React.useState(props.isView);

    const utils = new ViewUtils();

    const handleClose = (cancel, save) => {
        props.closeHandler(cancel, save);
        setOpen(false);
        setMessage("");
    };

    const cancel = () => {
        setOpen(false);
        handleClose(true, false);
    };

    React.useEffect(() => {

        setOpen(props.open);
        setTitle(props.dialogTitle)
    }, [props.open]);

    React.useEffect(() => {

        if (!utils.isNull(props.datamodel)) {
            setValues(props.datamodel)
        }
    }, [props.datamodel]);

    const save = () => {
        let formValue = props.formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            if (!utils.isNull(props.entityId)) {
                formValue.id = props.entityId;
            }
            else if (!utils.isNull(props.viewRef.props.location.state) && !utils.isNull(props.viewRef.props.location.state.selection)) {
                formValue.id = props.viewRef.props.location.state.selection;
            }
            if (!utils.isNull(props.entityInfo)) {
                formValue = { ...formValue, ...props.entityInfo }
            }
            if (!utils.isNull(props.multiEntity)) {
                formValue.IDs = props.multiEntity.map(e => e.Id);
            }
            let data = JSON.stringify(formValue);

            let fullUrl = utils.isNull(props.hasDocumentUpload) ? props.saveUrl + `?data=${data}` : props.saveUrl;

            let config = !utils.isNull(props.hasDocumentUpload) ?
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
                :
                null

            if (props.usePost) {
                fullUrl = props.saveUrl;

                config =
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
            }

            if (props.usePut) {
                fullUrl = props.saveUrl;

                config =
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
            }

            if (props.IsExternal) {
                utils.invokeUrlExternal(fullUrl,
                    (response) => {
                        if (response.MessageType === 'SUCCESS') {
                            if (!utils.isNull(response.Message)) {
                                props.viewRef.setState({
                                    Message: response.Message,
                                    MessageStyle: response.MessageType
                                })
                            }
                            props.closeHandler(false, true,response);
                        } else {
                            setMessage(response.Message);
                            setMessageStyle(response.MessageType)

                        }

                    },
                    (e) => {
                        setMessage(e.Message);
                        setMessageStyle(e.MessageType)
                        // utils.showMessage(props.viewRef, utils.SYSTEM_ERROR_MESSAGE, "ERROR");

                    },
                    config
                );
            }
            else {
                utils.invokeUrl(fullUrl,
                    (response) => {
                        if (response.MessageType === 'SUCCESS') {
                            if (!utils.isNull(response.Message)) {
                                props.viewRef.setState({
                                    Message: response.Message,
                                    MessageStyle: response.MessageType
                                })
                            }
                            props.closeHandler(false, true,response);
                        } else {
                            setMessage(response.Message);
                            setMessageStyle(response.MessageType)

                        }

                    },
                    (e) => {
                        setMessage(e.Message);
                        setMessageStyle(e.MessageType)
                        // utils.showMessage(props.viewRef, utils.SYSTEM_ERROR_MESSAGE, "ERROR");

                    },
                    config
                );
            }
        }
    }

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            setValues(values);
        }
    }

    return (
        <div className="w-100 lookup" >
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="responsive-dialog-title"
                disableBackdropClick={true}
                style={props.dialogStyle}
            >
                <DialogTitle className="breadcrumbs" disableTypography id="responsive-dialog-title">{title}</DialogTitle>
                <div style={{ paddingLeft: "24px", paddingRight: "44px" }}>
                    <AlertItem message={Message} alertStyle={MessageStyle} />
                </div>

                <DialogContent>
                    {React.cloneElement(props.children, { valueChangeHandler: (values) => { !utils.isNull(props.valueChangeHandler) ? props.valueChangeHandler(values) : handleFormValueChange(values) } })}
                </DialogContent>

                <DialogActions>
                    <Button onClick={save} color="primary" disabled={utils.isNull(values) || isView === true}>
                        save
                    </Button>
                    <Button onClick={cancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
