import React, { Component } from "react";
import { Table, Button, Popconfirm, Row, Col, Upload } from "antd";
import { ExcelRenderer } from "react-excel-renderer";
import { EditableFormRow, EditableCell } from "./editable";
import Icon from '@ant-design/icons';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import UploadTemplate from "../../../content/templates/LevyExemptUpload_Template.xlsx";

const utils = new ViewUtils();

export default class LEOExcelPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: [],
            rows: [],
            file: null,
            fileInfo: null,
            data: null,
            errorMessage: null,
            documentGenerated: false,
            messsageAlert: null,
            messageStyle: null,
            columns: [
                {
                    title: "ID Number",
                    dataIndex: "LearnerIDNo",
                    editable: false
                },
                {
                    title: "Provider SDL Number",
                    dataIndex: "ProviderSDLNumber",
                    editable: false
                },
                {
                    title: "Skills Programme Code",
                    dataIndex: "SkillsProgrammeCode",
                    editable: false
                },
                {
                    title: "Employer SDL Number",
                    dataIndex: "SkillsProgrammeTitle",
                    editable: false
                },
                {
                    title: "Funder",
                    dataIndex: "EmployerSDLNumber",
                    editable: false
                }
            ]
        };
    }

    handleSave = row => {
        const newData = [...this.state.rows];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        this.setState({ rows: newData });
    };

    checkFile(file) {
        let errorMessage = "";
        if (!file || !file[0]) {
            return;
        }
        const isExcel =
            file[0].type === "application/vnd.ms-excel" ||
            file[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isExcel) {
            errorMessage = "You can only upload Excel file!";
        }
        console.log("file", file[0].type);
        const isLt2M = file[0].size / 1024 / 1024 < 2;
        if (!isLt2M) {
            errorMessage = "File must be smaller than 2MB!";
        }
        console.log("errorMessage", errorMessage);
        return errorMessage;
    }

    fileHandler = fileList => {
        console.log("fileList", fileList);
        let fileObj = fileList;

        if (!fileObj) {
            this.setState({
                errorMessage: "No file uploaded!"
            });
            return false;
        }
        console.log("fileObj.type:", fileObj.type);
        if (
            !(
                fileObj.type === "application/vnd.ms-excel" ||
                fileObj.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
        ) {
            this.setState({
                errorMessage: "Unknown file format. Only Excel files are uploaded!"
            });
            return false;
        }
        this.setState({
            documentGenerated: false
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileObj);

        reader.onload = () => {
            let fileInfo = {
                name: fileObj.name,
                type: fileObj.type,
                size: Math.round(fileObj.size / 1000) + ' kB',
                base64: reader.result
            };
            this.setState({ fileInfo: fileInfo });
        }
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                let newRows = [];
                resp.rows.slice(3).map((row, index) => {
                    if (row && row !== "undefined") {
                        newRows.push({
                            LearnerIDNo: row[0],
                            ProviderSDLNumber: row[1],
                            SkillsProgrammeCode: row[2],
                            SkillsProgrammeTitle: row[3],
                            EmployerSDLNumber: row[4],
                            Sponsorship: row[5],
                            ProjectCode: row[6],
                            FinancialYear: row[7],
                            CommencementDate: row[8],
                            CompletionDate: row[9],
                            SocioEconomicStatus: row[10]
                        });
                    }
                });
                if (newRows.length === 0) {
                    this.setState({
                        errorMessage: "No data found in file!"
                    });
                    return false;
                } else {
                    this.setState({
                        file: fileObj,
                        cols: resp.cols,
                        rows: newRows,
                        errorMessage: null
                    });
                }
            }
        });
        return false;
    };

    successCallback = (response) => {

        //if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
        //    this.setState({ selectedAssessor: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase(), AssessmentEndDate: "" });
        //    //Force a refresh
        //    this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        //}
    }

    errorCallback = (response) => {
        //if (!utils.isNull(response.Message) && response.Message !== "") {
        //    this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        //}
    }

    handleSubmit = async () => {

        const data = {
            Person: this.state.rows,
            Document: this.state.fileInfo

        };

        utils.invokeUrl("api/sims/LearnerBSPSkillsProgrammeLEOUpload/BulkUploadLearnerBSPSkillsProgramme", (response) => {
            if (response.MessageType !== undefined && response.MessageType === "SUCCESS") {
                this.setState({
                    documentName: response.Message,
                    documentGenerated: true,
                    file: null,
                    fileInfo: null,
                    cols: [],
                    rows: []
                });
            }
            else {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );

    };

    FileDownload = () => {

        utils.invokeUrl("api/sims/LearnerBSPSkillsProgrammeLEOUpload/BulkUploadGetReport?FileName=" + this.state.documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleDelete = key => {
        const rows = [...this.state.rows];
        this.setState({ rows: rows.filter(item => item.key !== key) });
    };
    handleAdd = () => {
        const { count, rows } = this.state;
        const newData = {
            key: count,
            name: "User's name",
            age: "22",
            gender: "Female"
        };
        this.setState({
            rows: [newData, ...rows],
            count: count + 1
        });
    };

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            }
        };
        const columns = this.state.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave
                })
            };
        });
        return (
            <div>
                <AlertItem
                    message={this.state.messsageAlert}
                    alertStyle={this.state.messageStyle}
                />
                <Row gutter={16}>
                    <Col span={8}>
                        <a
                            href={UploadTemplate}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            Dowload template
                        </a>
                    </Col>
                    <Col
                        span={8}
                        align="center"
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        {this.state.rows.length > 0 && (

                            <Button
                                onClick={this.handleSubmit}
                                size="large"
                                type="primary"
                                style={{ marginBottom: 16, marginLeft: 10 }}
                            >
                                Import
                            </Button>

                        )}
                    </Col>
                </Row>
                <div>
                    <Upload
                        name="file"
                        beforeUpload={this.fileHandler}
                        onRemove={() => this.setState({ rows: [] })}
                        multiple={false}
                        showUploadList={false}
                    >
                        <Button>
                            <Icon type="upload" />Upload Excel File
                        </Button>
                    </Upload>
                    <div>
                        {this.state.fileInfo && this.state.fileInfo.name}
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Table
                        components={components}
                        rowClassName={() => "editable-row"}
                        dataSource={this.state.rows}
                        columns={columns}
                    />
                </div>
                <Col span={8}>
                    {this.state.documentGenerated && (
                        <a
                            onClick={this.FileDownload}
                        >
                            Click here to download Bulk Import success and exception report
                        </a>
                    )}
                </Col>
            </div>
        );
    }
}
