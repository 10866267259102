import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();


export const DGApplicationCreationForm = React.forwardRef((props, ref) => {

    const [FinancialYear, setFinancialYear] = React.useState(null);
    const [DGType, setDGType] = React.useState(null);
    const [dependency, setDependency] = React.useState(props.dependency);

    const showDGIntervention = (FinancialYearID, DGTypeID) => {
        if (FinancialYearID != undefined) { setFinancialYear(FinancialYearID.Id) }
        if (DGTypeID != undefined) { setDGType(DGTypeID.Id) }
    }

    React.useEffect(() => {
        setDependency(props.dependency);
    }, [props.dependency]);

    return (
        <EntityForm ref={ref}
            viewId="dgApplication"
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="dgApplicationForm">
    
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <SelectItem
                                        dataUrl="api/sims/dgapplication/GetDGFinancialYearLookupList"
                                            id="FinancialYearID"
                                     
                                            label="Financial Year"
                                            className="w-100"
                                            required={true}
                                            form={form} />
                                    </td>

                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/dgapplication/getlookuplistdiscretionarygranttype"
                                            id="DGTypeID"
                                            label="Window Type"
                                            required={true}
                                            className="w-100"
                                            form={form}
                                            hidden={showDGIntervention(form.values["FinancialYearID"])}
                                            disabled={FinancialYear != null ? false : true} />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/dgapplication/GetLookupListDiscretionaryGrantIntervention?parameter="
                                            id="DGInterventionID"
                                            required={true}
                                            label="Intervention Type"
                                            className="w-100"
                                            form={form}
                                            hasDependency={true}
                                            dependency={dependency} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </form>
            }
        </EntityForm>
    );
})

export default DGApplicationCreationForm;
