import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Row, Col, Form } from 'react-bootstrap';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import QualificationSetupSearch from './QualificationSetupSearch';
import OFOSearch from './OFOSearch';
import { LookupField } from '../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function ProgTypeCheck(value) {
    if (value !== undefined && value !== null) {
        if (value.Description === "Qualification") {
            return false
        }
        else if (value.Description !== "Qualification") {
            return true
        }
    }
}

const utils = new ViewUtils();
export const SAQAXMLImportForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm
            ref={ref}
            viewId="saqaXMLImport"
            dataURL=''
            values={props.data}
            entityLookupController={props.entityLookupController}
            org="saseta"
            entityLookupId={props.id}
            useCustomSettings={false}
            valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <Form id="saqaXMLImportForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="SAQA Import Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/saqaxmlimport/getlookuplistprogrammetype"
                                        id="ProgrammeTypeID"
                                        key={tabValue}

                                        required
                                        label="Programme Type"
                                        className="w-100"
                                        form={form}
                                        disabled={!utils.isNull(props.id) ? !props.menuPermissionItem.Edit : !props.menuPermissionItem.Add} />
                                </Col>
                                <Col xs lg="6">
                                    <CustomTextField
                                        id="ProgrammeCode"
                                        key={tabValue}
                                        required={ProgTypeCheck(form.values["ProgrammeTypeID"])}
                                        label="Programme Code"
                                        className="w-100 "
                                        validationRegex={/^[0-9]+$/g}
                                        validationMessage="This field must be numeric"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                        disabled={!utils.isNull(props.id) ? !props.menuPermissionItem.Edit : !props.menuPermissionItem.Add}
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xs lg="6">
                                    <SelectItem
                                        dataUrl="api/sims/saqaxmlimport/GetLookupListRegistrationStatus"
                                        id="RegistrationStatusID"
                                        key={tabValue}

                                        required
                                        label="Registration Status"
                                        className="w-100"
                                        form={form}
                                        disabled={!utils.isNull(props.id) ? !props.menuPermissionItem.Edit : !props.menuPermissionItem.Add} />
                                </Col>
                                {!(ProgTypeCheck(form.values["ProgrammeTypeID"]))?
                                <Col xs lg="6">
                                    <SelectItem
                                            dataUrl="api/sims/saqaxmlimport/GetLookupListQABody"
                                        id="QualityAssuranceBodyID"
                                        key={tabValue}

                                        required
                                        label="Quality Assurance Body"
                                        className="w-100"
                                        form={form}
                                        disabled={!utils.isNull(props.id) ? !props.menuPermissionItem.Edit : !props.menuPermissionItem.Add} />
                                </Col>:""}
                            </Form.Row>
                        </TabPanel>
                    </FormTabContainer>
                </Form>
            }
        </EntityForm>
    );
})
