import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const utils = new ViewUtils();
export const OnlineProviderDetailsVerificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="provider" values={props.data} dataURL='api/sims/OnlineProviderApproval/GetApprovalVerificationFind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProviderForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="General Details" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%" index={0}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="DetailsCompletedID"
                                                key={tabValue}
                                                label="Training Provider Details Complete"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ScopeCompleteID"
                                                key={tabValue}
                                                label="Training Provider Scope Complete"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ProofOfRegistrationID"
                                                key={tabValue}
                                                label="Proof of registration of the company / organisation"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="QualityManagementSystemProofID"
                                                key={tabValue}
                                                label="Quality Management System"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ProofOfOrganisationEvaluationID"
                                                key={tabValue}
                                                label="Proof that the organisation can develop, deliver, and evaluate learning programmes"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="PoliciesAndProceduresID"
                                                key={tabValue}
                                                label="Management of Off-site and Work-site components (Policies and procedures)"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ProofOfFinancialResourcesID"
                                                key={tabValue}
                                                label="Proof of financial resources for training"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="StaffSelectionProceduresID"
                                                key={tabValue}
                                                label="Staff selection, appraisal and development policies and procedures"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ProofOfRegistrationForAssessorsID"
                                                key={tabValue}
                                                label="Proof of registration of Assessors or access to Assessors"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="SystemManagementAssessmentsID"
                                                key={tabValue}
                                                label="System for the Management of Assessments"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="ModerationOfTrainingID"
                                                key={tabValue}
                                                label="System for the Moderation of Training"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="LearnerEntryID"
                                                key={tabValue}
                                                label="Learner entry, guidance, and support system"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="AdminitrativeRecordID"
                                                key={tabValue}
                                                label="Administrative Record Keeping and Reporting Capacity"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineProviderApproval/GetLookupList?listId=YesNo"
                                                id="LetterOfIntentID"
                                                key={tabValue}
                                                label="Letter of intent to only apply for accreditation with HWSETA"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/OnlineModeratorApproval/GetLookupList?listId=OnlineProviderApplicationStatus"
                                                id="OnlineProviderApplicationStatusID"
                                                key={tabValue}
                                                label="Status"
                                                parentId={0}
                                                required
                                                className="w-100"
                                                form={form} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

