import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EnhancedToolbar from '../../widgets/Toolbar';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableContainer from '@material-ui/core/TableContainer';
import { useEffect } from 'react';
import Select from '@material-ui/core/Select';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RadioButton = (props, ref) => {
    const [value, setValue] = React.useState(typeof props.initial === 'string' || props.initial instanceof String ? props.initial : props.initial.toString());

    function handleChange(event) {
        const { target: { value: _value } } = event;


        if (typeof _value === 'string' || _value instanceof String) {
            setValue(_value);
            //value = _value;
        }
        else {
            setValue(_value.toString());
            //value = _value.toString();
        }

        props.handleScopeSelected(event);
    }

    React.useEffect(() => {
        if (typeof props.initial === 'string' || props.initial instanceof String) {
            setValue(props.initial);
            //value = props.initial;
        }
        else {
            setValue(props.initial.toString());
            //value = props.initial.toString();
        }

    });

    return (
        <FormControl style={{ paddingLeft: 5 }}>
            <RadioGroup onChange={handleChange} value={typeof value === 'string' || value instanceof String ? value : value.toString()} id={props.id} aria-label="y/n" name="wspatr module" >
                <div style={{ display: 'inline' }}>
                    <FormControlLabel value="1" id={props.id + "11"} control={<StyledRadio id={props.id + "1"} />} label="Yes" />
                    <FormControlLabel value="2" id={props.id + "22"} control={<StyledRadio id={props.id + "2"} />} label="No" />
                </div>

            </RadioGroup>
        </FormControl>
    );
}

const CommentInput = (props) => {
    return (
        <FormControl>
            <TextField
                style={{ width: 500 }}
                variant="filled"
                onChange={(e) => props.handleComment(e)}
                isRequired="true"
                required
                id={props.id}
                error={((props.question1 == "" || props.question1 == undefined) && (props.question2 == "" || props.question2 == undefined)) && props.submitted}
                helperText={((props.question1 == "" || props.question1 == undefined) && (props.question2 == "" || props.question2 == undefined)) && props.submitted ? "Required" : null}
            />

        </FormControl>
    );
}


function createData(number, checkList, confirmation, submited, comments) {
    return { number, checkList, confirmation, submited, comments };
}

const utils = new ViewUtils();
const useStyles = makeStyles({
    head: {
        width: '100%',
    },

    root: {
        //   width: '90%',
        margin: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    container: {
        maxHeight: 600,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 10,
    },
    pos: {
        marginBottom: 12,
    },
    icon: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',

        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    table: {
        minWidth: 700,
        width: "100%"
    },
    checkedIcon: {
        backgroundColor: '#01579B',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#01579B',
        },
    },
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            id={props.id}
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 10,

    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 10,
        fontWeight: 400
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);


const StakeholderfeedbackForm = React.forwardRef((props, ref) => {
    const classes = useStyles();

    const [Stakeholderfeedback, setStakeholderfeedback] = React.useState(props.state.Stakeholderfeedback);

    const [FinancialObject, setFinancialObject] = React.useState({});
    const [FinancialObject2, setFinancialObject2] = React.useState(undefined);

    const [FinancialStakeholderFeedback, setFinancialStakeholderFeedback] = React.useState(undefined);

    const [ShowTable, setShowTable] = React.useState(false);

    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const [FinancialYearObject, setFinancialYearID] = React.useState("Financial Year");


    const handleScopeSelected = (event) => {
        props.valueChangeHandler(event.target.value, "Question1YesNoID");

    }

    const handleScopeSelected1 = (event) => {
        props.valueChangeHandler(event.target.value, "Question2YesNoID");

    }

    const handleScopeSelected2 = (event) => {
        props.valueChangeHandler(event.target.value, "Question3YesNoID");

    }



    const handleScopeSelected3 = (event) => {
        props.valueChangeHandler(event.target.value, "Question4YesNoID");

    }

    const handleScopeSelected4 = (event) => {
        props.valueChangeHandler(event.target.value, "Question5YesNoID");

    }

    const handleScopeSelected5 = (event) => {
        props.valueChangeHandler(event.target.value, "Question6YesNoID");

    }


    const handleScopeSelected6 = (event) => {
        props.valueChangeHandler(event.target.value, "Question7YesNoID");

    }

    const handleScopeSelected7 = (event) => {
        props.valueChangeHandler(event.target.value, "Question8YesNoID");

    }

    const handleComment = (event) => {
        props.valueChangeHandler(event.target.value, "Question1");

    }

    const handleComment1 = (event) => {
        props.valueChangeHandler(event.target.value, "Question2");

    }

    const handleComment2 = (event) => {
        props.valueChangeHandler(event.target.value, "Question3");

    }

    const handleComment3 = (event) => {
        props.valueChangeHandler(event.target.value, "Question4");

    }

    const handleComment4 = (event) => {
        props.valueChangeHandler(event.target.value, "Question5");

    }
    const handleComment5 = (event) => {
        props.valueChangeHandler(event.target.value, "Question6");

    }

    const handleComment6 = (event) => {
        props.valueChangeHandler(event.target.value, "Question7");

    }

    const handleComment7 = (event) => {
        props.valueChangeHandler(event.target.value, "Question8");

    }

    //const handleComment7 = () => {
    //    if (event) {
    //        var i = "hjbhj";
    //    }

    //    if (ref.current) {
    //        var i = ref.current.getValue();

    //            const url = "api/sims/StakeholderFeedback/FindStakeholderFeedbackDetailsById" + "?organisationId=" + props.state.Stakeholderfeedback.OrganisationID + "&financialYearId=" + i.FinancialYearID.Id;
    //            utils.invokeUrl(url, (response) => {
    //                if (response.Question1YesNoID == 0 || response.Question2YesNoID == 0) {
    //                    var b = response.Question1YesNoID;
    //                }

    //            }, {});

    //    }

    //}


    const handleFinancialId = () => {
        setStakeholderfeedback(props.state.Stakeholderfeedback);
    }





    const fields = [
        createData('1.', " Did you attend BANKSETA Information Session? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected(values)} initial={props.state.Stakeholderfeedback.Question1YesNoID} id="Question1YesNoID" />, <CommentInput handleComment={(values) => handleComment(values)} id="1" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question1YesComment} question2={props.state.Stakeholderfeedback.Question1NoComment} initial={Stakeholderfeedback.Question1NoComment} initial2={Stakeholderfeedback.Question1YesComment} />),

        createData('2.', " Have you ever attended SDF Training? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected1(values)} initial={props.state.Stakeholderfeedback.Question2YesNoID} id="Question2YesNoID" />, <CommentInput handleComment={(values) => handleComment1(values)} id="2" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question2YesComment} question2={props.state.Stakeholderfeedback.Question2NoComment} initial={Stakeholderfeedback.Question2NoComment} initial2={Stakeholderfeedback.Question2YesComment} />),

        createData('3.', " Is the online system user friendly? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected2(values)} initial={props.state.Stakeholderfeedback.Question3YesNoID} id="Question3YesNoID" />, <CommentInput handleComment={(values) => handleComment2(values)} id="3" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question3YesComment} question2={props.state.Stakeholderfeedback.Question3NoComment} initial={Stakeholderfeedback.Question3NoComment} initial2={Stakeholderfeedback.Question3YesComment} />),

        createData('4.', " Would you suggest any system improvement? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected3(values)} initial={props.state.Stakeholderfeedback.Question4YesNoID} id="Question4YesNoID" />, <CommentInput handleComment={(values) => handleComment3(values)} id="4" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question4YesComment} question2={props.state.Stakeholderfeedback.Question4NoComment} initial={Stakeholderfeedback.Question4NoComment} initial2={Stakeholderfeedback.Question4YesComment} />),

        createData('5.', " Are you willing to take BANKSETA previous funded learners for workplace experience and internship programme? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected4(values)} initial={props.state.Stakeholderfeedback.Question5YesNoID} id="Question5YesNoID" />, <CommentInput handleComment={(values) => handleComment4(values)} id="5" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question5YesComment} question2={props.state.Stakeholderfeedback.Question5NoComment} initial={Stakeholderfeedback.Question5NoComment} initial2={Stakeholderfeedback.Question5YesComment} />),

        createData('6.', " Can BANKSETA contact your organisation regarding programmes such as Learnerships, Work Integrated Learning and/or Internships? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected5(values)} initial={props.state.Stakeholderfeedback.Question6YesNoID} id="Question6YesNoID" />, <CommentInput handleComment={(values) => handleComment5(values)} id="6" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question6YesComment} question2={props.state.Stakeholderfeedback.Question6NoComment} initial={Stakeholderfeedback.Question6NoComment} initial2={Stakeholderfeedback.Question6YesComment} />),

        createData('7.', " Did you consult all the parties involved in the signoff of WSP/ATR submission? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected6(values)} initial={props.state.Stakeholderfeedback.Question7YesNoID} id="Question7YesNoID" />, <CommentInput handleComment={(values) => handleComment6(values)} id="7" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question7YesComment} question2={props.state.Stakeholderfeedback.Question7NoComment} initial={Stakeholderfeedback.Question7NoComment} initial2={Stakeholderfeedback.Question7YesComment} />),

        createData('8.', " Did you keep records of minutes for the Skills Development Committee meetings? ", <RadioButton handleScopeSelected={(values) => handleScopeSelected7(values)} initial={props.state.Stakeholderfeedback.Question8YesNoID} id="Question8YesNoID" />, <CommentInput handleComment={(values) => handleComment7(values)} id="8" submitted={props.state.submitted} question1={props.state.Stakeholderfeedback.Question8YesComment} question2={props.state.Stakeholderfeedback.Question8NoComment} initial={Stakeholderfeedback.Question8NoComment} initial2={Stakeholderfeedback.Question8YesComment} />),
    ];



    return (
        <EntityForm ref={ref}
            viewId="organisation"
            // dataURL='api/sims/lettercreationdetails/getfind' 
            dataURL='api/sims/organisationbankdetail/GetFind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController}
            org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>

            {form =>
                <form className={form.classes.container} id="stakeHolderFeedBackForm" value={tabValue}>
                    <div className="row" style={{ width: "90%" }}>

                        {/* {props.location.state.FinancialYearID === null ? <p align="center">PLEASE SELECT FINANCIAL YEAR</p> : */}


                        <div className="col w-100" >
                            <SelectItem
                                dataUrl="api/sims/LodgeAppeal/GetFinancialYearsNotAll"
                                id="FinancialYearObject"
                                required
                                key={tabValue}
                                label="Financial Year"
                                className="{classes.select} w-100"
                                form={form}
                            />




                            <br />
                            <br />
                            {props.state.showTable ?
                                <TableContainer className={classes.container}>
                                    <Table className={classes.table} size="small" >
                                        <TableHead >
                                            <TableRow>
                                                <StyledTableCell align="left"># </StyledTableCell>
                                                <StyledTableCell align="left">Feedback on</StyledTableCell>

                                                <StyledTableCell align="center">(Y/N) </StyledTableCell>
                                                <StyledTableCell align="center">Comments    </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>


                                            {fields.map((row) => (
                                                <StyledTableRow key={row.number}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.number}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" >{row.checkList}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.confirmation}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.submited}</StyledTableCell>
                                                    <StyledTableCell align="center">{row.comments}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer> : null
                            }

                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})

export default StakeholderfeedbackForm;