import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import AETSetupSearch from '../../program/AETSetupSearch';
import ViewUtils from "../../../ViewUtils";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerAETForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }


    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }


    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Unemployed") {
                return false
            }
        }
    }

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "HWSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    return (
        <EntityForm ref={ref} values={props.data} viewId="AETSetup" dataURL="api/sims/learneraet/GetLearnerLinkedAETDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerAETForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="AET Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Provider"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Provider"
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                                required
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learneraet/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="AETID"
                                                label="AET Programme"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <AETSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learneraet/AETSearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("AETID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Actual Start Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="End Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.StartDate)
                                                            && formValues.StartDate.getTime() < formValues.EndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learneraet/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learneraet/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Funder"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                filterOptions={option => {
                                                    return option.Id == 1 && option.Description == "HWSETA Funded";
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learneraet/GetProjectByInterventionType"
                                                id="ProjectID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                key={tabValue}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Last Signature Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Most Recent Registration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FundingAgreementNumber"
                                                key={tabValue}
                                                label="Learner Agreement Number"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CumulativeSpent"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Amount Spent Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CostPerLearner"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Cost Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learneraet/GetLookupList?listId=AETType"
                                                id="AETTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="AET Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learneraet/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }} filterOptions={option => {
                                                    return option.Id !== 3 && option.Description !== "Unknown";
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {
                                                (!props.hideFields) ?
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                    /> :
                                                    ""
                                            }
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="InformationVerified"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Information Verified"
                                            />
                                        </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="VerifiedDate"
                                                key={tabValue}
                                                disabled
                                                label="Verifier Date"
                                                className='{classes.textField} w-100'
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="VerifiedByUser"
                                                key={tabValue}
                                                label="Verifier"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                    <tr>
                                        <td >
                                            <SelectItem
                                                        dataUrl={'api/sims/learneraet/GetProgrammeStatusByRole?parameter=[{"Name":"StatusID","Value":' + GetProgrammeStatusID(form.values["StatusID"]) + '}]'}
                                                id="StatusID"
                                                parentId={0}
                                                required
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                 
                                           
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form} 
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["StatusID"])}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learneraet/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["StatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["StatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminiationDate"
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        label="Actual Terminiation Date"
                                                        className='{classes.textField} w-100'
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learneraet/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form} 
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form} 
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form} 
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegistrationNumber"
                                                        key={tabValue}
                                                        label="Registration Number"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
