import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Provider.css";
import { ProviderForm } from './ProviderForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";



const providerRef = React.createRef();
const utils = new ViewUtils();

class Provider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ProviderDetails: {
                LegalName: "",
                SDLNumber: "",
                AccreditationCheck: false
            },
            showTable: true,
            RequiredFields: null,
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                        ]
                    },

                    {
                        type: "devider"
                    },

                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.onMenuSpecificPermissionItem('/provider').Add || this.props.onMenuSpecificPermissionItem('/provider').Edit },
                            { id: 'contacts', label: 'Contacts', tooltip: '', visible:this.props.onMenuSpecificPermissionItem('/providerContacts').View },
                            { id: 'users', label: 'Users', tooltip: '', visible:this.props.onMenuSpecificPermissionItem('/providerUsers').View },
                            {
                                contextMenu: [
                                    { id: "unitStandards", description: "Unit Standards", visible:this.props.onMenuSpecificPermissionItem('/providerUnitStandardSearch').View },
                                    //{ id: "qualifications", description: "SAQA Qualifications" },
                                    { id: "skillsProgramme", description: "Skills Programmes", visible:this.props.onMenuSpecificPermissionItem('/providerSkillsProgrammeSearch').View },
                                    { id: "learnerships", description: "Learnerships", visible:this.props.onMenuSpecificPermissionItem('/providerLearnershipSearch').View },
                                    //{ id: "qctoqualifications", description: "QCTO Qualifications" }
                                    { id: "trades", description: "Artisans and Technicians Programmes", visible:this.props.onMenuSpecificPermissionItem('/providerTradeSearch').View }
                                ],
                                id: 'programs', label: 'Programmes'
                            },
                            { id: 'learners', label: 'Learners', tooltip: '', visible:this.props.onMenuSpecificPermissionItem('/providerLearner').View },
                            //{ id: 'assesors', label: 'Assessor', tooltip: '' },
                            //{ id: 'moderator', label: 'Moderator', tooltip: '' },
                            { id: 'crm', label: 'CRM', tooltip: '', visible:this.props.onMenuSpecificPermissionItem('/providerCRM').View }

                        ]
                    }
                ]
            }

        };
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {

            if (!utils.isNull(values.ProviderAccreditationStatusID) && !utils.isNull(values.AccreditationStartDate)) {
                //if (!utils.isNull(values.AccreditationStartDate)) {
                //    //Remember to change this from checking using Id as this can change in the database
                //    if (values.ProviderAccreditationStatusID.Id === "2") {
                //        utils.autoSetDate(this, providerRef, values.AccreditationStartDate, 2, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                //    }
                //    else if (values.ProviderAccreditationStatusID.Description === "Accredited") {
                //        utils.autoSetDate(this, providerRef, values.AccreditationStartDate, 5, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                //    }
                //}

                if (values.ProviderAccreditationStatusID.Description === "Full Accreditation") {
                    let requiredFields = {
                        DHETRegistrationStartDate: "DHETRegistrationStartDate",
                        DHETRegistrationEndDate: "DHETRegistrationEndDate",
                        DHETRegistrationNumber: "DHETRegistrationNumber"
                    };

                    this.setState({ RequiredFields: requiredFields })
                }
                else {
                    this.setState({ RequiredFields: null });
                }
            }
            this.setState({ ProviderDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("close" === buttonId) {
            this.closeView();
        }
        else if ("contacts" === buttonId) {
            this.props.history.push({
                pathname: '/providerContacts',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            });
        }
        else if ("users" === buttonId) {
            this.props.history.push({
                pathname: '/providerUsers',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("siteVisits" === buttonId) {

            this.props.history.push({
                pathname: '/providerSiteVisits',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        } else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/providerLearner',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/providerCRM',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("assesors" === buttonId) {
            this.props.history.push({
                pathname: "/providerAssessorSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("moderator" === buttonId) {
            this.props.history.push({
                pathname: "/providerModeratorSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("accreditationHistory" === buttonId) {
            this.props.history.push({
                pathname: "/providerAccreditationHistory",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("monitoringAndEvaluation" === buttonId) {
            this.props.history.push({
                pathname: "/providerMonitoringAndEvaluation",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {
        if ("unitStandards" === menu.id) {
            this.props.history.push({
                pathname: '/providerUnitStandardSearch',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("qualifications" === menu.id) {
            this.props.history.push({
                pathname: '/providerQualificationsSearch',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("skillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: "/providerSkillsProgrammeSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("learnerships" === menu.id) {
            this.props.history.push({
                pathname: "/providerLearnershipSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("trades" === menu.id) {
            this.props.history.push({
                pathname: "/providerTradeSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber,
                    viewOnly:this.props.location.state.viewOnly
                }
            })
        }
        else if ("qctoqualifications" === menu.id) {
            this.props.history.push({
                pathname: '/providerModuleQualificationsSearch',
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
    }

    successCallback = (response) => {

        this.setState({ message: response.Message, messageStyle: response.MessageType, showTable: false }, () => {
            this.setState({ showTable: true });
        });
    }

    save() {
        let formValue = providerRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            utils.invokeUrl("api/sims/providerdetails/UpdateProvider", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(formValue)
            });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/providerSearch',
            state: { selection: this.props.location.state.providerID }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Provider " + (this.state.ProviderDetails.LegalName !== null ? "> " + this.state.ProviderDetails.LegalName + (this.state.ProviderDetails.SDLNumber !== null ? " - " + this.state.ProviderDetails.SDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Provider" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "55vh", maxHeight: "55vh" }}>
                                    {this.state.showTable === true && (<ProviderForm
                                        ref={providerRef}
                                        id={this.props.location.state.selection}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        requiredFields={this.state.RequiredFields}
                                        editPermission={this.props.menuPermissionItem.Edit} 
                                        addPermission={this.props.menuPermissionItem.Add} 
                                        viewOnly={this.props.location.state.viewOnly} 
                                        viewRef={this}
                                    />)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Provider));

