import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./OnlineRegistrationTypeSelect.css";
import withMenuPermission from "../../widgets/withMenuPermission";
import { Button } from "react-bootstrap";
import LoadingIndicatorLogin from '../../LoadingIndicatorLogin';
import simslogoLoading from '../../../content/images/logos/hwseta_logo.jpg'


const registrationTypes = [
    { Id: "assessor", Name: "Assessor Registration Application" },
    { Id: "moderator", Name: "Moderator Registration Application" },
    { Id: "provider", Name: "Training Provider Accreditation Application" }
]


class OnlineRegistrationTypeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegistrationType: "-1",
            message: "",
            loading: true
        };
    }

    back() {
        this.props.history.push({
            pathname: "/login"
        })
    }

    select() {
        this.setState({ message: "" });
        this.props.history.push({
            pathname: "/OnlineRegistrationSecondryTypeSelect",
            state: {
                selectedRegistrationType: this.state.selectedRegistrationType
            }
        })
    }

    render() {
        return (
            <div className="OnlineRegistration-container">
                <img src={simslogoLoading} className="logo-bottom-left" alt="logo" />
                <div className="OnlineRegistrationSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Select an online registration type:</p>
                    <form className="style-2" id="scroll">
                        {registrationTypes.map(RegType => (
                            <div className="form-check" key={RegType.Id}>
                                <label>
                                    <input type="radio"
                                        value={RegType.Id}
                                        checked={this.state.selectedRegistrationType === RegType.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedRegistrationType: ev.target.value })}
                                    />
                                    <span className="label-text"> {RegType.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="OnlineRegistration-btn"
                                        disabled={this.state.selectedRegistrationType === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}


export default withRouter(withMenuPermission(OnlineRegistrationTypeSelect));
