import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import InstitutionSearch from '../../program/InstitutionSearch';
import OFOSearch from '../../program/OFOSearch';
import ProgramDocuments from "../../crm/ProgramDocuments";
import ProgramDocumentsDialog from "../../crm/ProgramDocumentsDialog";
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';
import ViewUtils from "../../../ViewUtils";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const utils = new ViewUtils();
export const LearnerInternshipForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [DocURL, setDocURL] = React.useState(null);

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    const config = {
        moduleName: "InternshipsSetup",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/indicium/InternshipsSetup/AddInternshipDocument"
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 1) {
            setDocURL("api/indicium/InternshipsSetup/getCRMDocuments");
        }
        else {
            setDocURL(null);
        }
    };
    

    const setDialogOpen = () => {
        setdocumentsDialogOpen(true);
    };

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "HWSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function nonSpecificCheck(value) {
        if (value === true) {
            return true;
        }
        return false;
    }

    function GetID(value) {
        //console.clear();
        //console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }


    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    const handleCellSelect = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            const link = document.createElement('a');
            link.href = '/api/indicium/InternshipsSetup/getCRMDocument?documentID=' + rowid;
            link.download = 'download';
            link.target = '_blank';
            link.click();
        }
    };

    const postUploadHandler = (save, cancel) => {
        //Successful upload
        if (save === true) {
            setdocumentsDialogOpen(false);
        }
        //Close dialog
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
        }
        //Error on upload
        else {
            setdocumentsDialogOpen(false);
        }
    };

    

    return (
        <EntityForm ref={ref} values={props.data} viewId="InternshipsSetup" dataURL="api/sims/learnerinternship/GetLearnerLinkedInternshipDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerInternshipForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Internship Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Placement" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                               {/* <Tab label="Supporting Documents" {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null }} />*/}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerinternship/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Funder"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetProjectByInterventionType"
                                                id="ProjectID"
                                                parentId={0}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                key={tabValue}
                                                label="Project"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ServiceLevelAgreementNumber"
                                                key={tabValue}
                                                label="Learner Agreement Number"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                filterOptions={option => {
                                                    return option.Id == 1 && option.Description == "Employed";
                                                }}
                                            />
                                        </td>
                                        <td >
                                            
                                        <CustomTextField
                                                id="YearOfStudy"
                                                key={tabValue}
                                                label="Year Qualification Completed"
                                                validationRegex={/^(?:[1-9][0-9][0-9][0-4]|[1-9][0-9][0-2][\d]?)$/g}
                                                validationMessage="This field must be numeric"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CostPerLearner"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Cost Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CumulativeSpent"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Amount Spent per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="HostEmployerID"
                                                label="Host Employer"
                                                className="w-100"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerinternship/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("HostEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=EnrolmentStatusReason"
                                                id="EnrolmentStatusReasonID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Dropout Reasons"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsIndustrySpecific"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Specific?"
                                            />
                                        </td>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IndustryNonSpecific"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Non Specific?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NonSpecificSICCode"
                                                key={tabValue}
                                                label="Non Specific SIC Code"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100 "
                                                required={nonSpecificCheck(form.values["IndustryNonSpecific"])}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="InternQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InternQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl={'api/sims/learnerinternship/GetLookupListNQFLevel?parameter=[{"Name":"NQFLevelID","Value":' + GetID(form.values["NQFLevelID"]) + '}]'}
                                                id="NQFLevelID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="NQF Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="Name of Institution"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value);
                                                        form.values['PublicPrivateID'] = { Id: 1, Description: 'Public' };
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=InternshipType"
                                                id="InternshipTypeID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Internship Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetDurationByInterventionType"
                                                id="DurationID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Duration"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Most Recent Registration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="InternshipStartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Actual Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                <tr>
                                    <td>
                                        <CustomDatePicker
                                            id="InternshipEndDate"
                                            validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            label="End Date"
                                            className='{classes.textField} w-100 '
                                            required
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                        />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerinternship/GetLookupList?listId=PublicPrivate"
                                                id="PublicPrivateID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Is the training provider Public/Private?"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="LastSignatureDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Last Signature Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnerinternship/GetProgrammeStatusByRole?parameter=[{"Name":"InternshipStatusID","Value":' + GetProgrammeStatusID(form.values["InternshipStatusID"]) + '},{"Name":"IsCreate","Value":"'+props.IsCreate+'"}]'}
                                                        id="InternshipStatusID"
                                                        parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        required={!props.hideFields }
                                                        key={tabValue}
                                                        label="Internship Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="SICCode"
                                                        key={tabValue}
                                                        label="SIC Code"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CompletionDate"
                                                        key={tabValue}
                                                        label="Completion Date"
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="VerifiedByUser"
                                                        key={tabValue}
                                                        label="Verifier"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td xs lg="6">
                                                    <CustomCheckbox
                                                        id="InformationVerified"
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        form={form}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                        label="Information Verified"
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="VerifiedDate"
                                                        key={tabValue}
                                                        disabled
                                                        label="Verifier Date"
                                                        className='{classes.textField} w-100'
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        required={TerminationCheck(form.values["InternshipStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["InternshipStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerinternship/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["InternshipStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["InternshipStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminatedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Actual Terminated Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["InternshipStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100'
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="RegisteredBy"
                                                            key={tabValue}
                                                            label="Registered By"
                                                            className="w-100 "
                                                            disabled
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                </tr>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <tr>
                                <td>
                                    <GPSCoordinatesAPI
                                        id="Address"
                                        key={tabValue}
                                        className="w-100"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Address"
                                        form={form}
                                        updateGPSAddress={updateGPSAddress}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress1"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        label="Employer Physical Address 1"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress2"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 2"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress3"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        label="Employer Physical Address 3"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <AddressCodeItem
                                        id="PhysicalCode"
                                        addressId="Physical"
                                        value={physicalCodeValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                        municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                        districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                        urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                        suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                        cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                        className="w-100"
                                        label="Employer Physical Code"
                                        hasMunicipalityID={true}
                                        hasProvinceID={true}
                                        hasCityID={true}
                                        hasDistrictID={false}
                                        hasUrbanRuralID={true}
                                        hasSuburbID={true}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentStartDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Placement Start Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentEndDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Placement End Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <LookupField
                                        id="PlacementEmployerID"
                                        label="Employer"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        className="w-100"
                                        form={form}
                                    >
                                        <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerinternship/EmployerSearch"
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("PlacementEmployerID", value)
                                            }} autoFetchData={false} selection={props.selection} />
                                    </LookupField>
                                </td>
                            </tr>
                            <tr>
                            <td >
                                <SelectItem
                                    dataUrl="api/sims/learnerinternship/GetLookupList?listId=TypeOfPlacement"
                                    id="TypeOfPlacementID"
                                    parentId={0}
                                    key={tabValue}
                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                    label="Type Of Placement"
                                    className="{classes.select} w-100"
                                    allowEmptyValue={true}
                                    form={form}
                                    InputProps={{
                                        readOnly: props.disableFields
                                    }}
                                />
                                </td>
                            </tr>
                            <tr>
                            <td>
                                <CustomTextField
                                    id="EmployerContractNumber"
                                    key={tabValue}
                                    label="Employer Contract Number"
                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                    className="w-100 "
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    form={form}
                                />
                                </td>
                            </tr>
                            <tr>
                            <td >
                                <SelectItem
                                    dataUrl="api/sims/learnerinternship/GetLookupList?listId=PlacementStatus"
                                    id="PlacementStatusID"
                                    parentId={0}
                                    key={tabValue}
                                    disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                    label="Placement Status"
                                    className="{classes.select} w-100"
                                    allowEmptyValue={true}
                                    form={form}
                                    InputProps={{
                                        readOnly: props.disableFields
                                    }}
                                />
                                </td>
                            </tr>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>

                                    <div className="searchResults">
                                       <p className="legend">
                                       Documents
                                    </p>
                                
                                <ProgramDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)}
                                    documentsDataUrl={DocURL} moduleName={config.moduleName} selection={form.values["ID"]} />

                                <br />
                                <br />

                                         <Button onClick={setDialogOpen} color="primary">
                                          Add Document 
                                         </Button>
                            </div>
                            <div>
                                <ProgramDocumentsDialog
                                    open={documentsDialogOpen}
                                    closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                    postUrl={config.postUrls.documents}
                                    EntityID={form.values["ID"]}
                                />
                            </div>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
