import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import SearchView from "../../widgets/SearchView";
import MathScienceSupportSearchForm from '../program/MathScienceSupportSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'SchoolName', numeric: false, disablePadding: true, label: 'School Name' },
    { id: 'TrainingProvider', numeric: false, disablePadding: true, label: 'Training Provider' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class LearnerMathScienceSupport extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.location.state.learnerID) ? [
            { Name: "SchoolName", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]
            :
            [
                { Name: "SchoolName", Value: "" },
                { Name: "LearnerID", Value: this.props.location.state.learnerID }
            ];

        let model = !utils.isNull(this.props.location.state.learnerID) ?
            {
                SchoolName: "",
                ProgrammeID: this.props.programmeId,
                LearnerID: this.props.location.state.learnerID

            }
            :
            {
                SchoolName: "",
                LearnerID: this.props.location.state.learnerID
            }


        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }


        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                                { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.View },
                                { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit, disabled: true },
                                { id: "delete", label: "", tooltip: "Remove Math and Science Support", icon: "DeleteIcon", visible: this.props.menuPermissionItem.Delete }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for unit standard removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddLearnerMathScienceSupport',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    MathScienceSupportID: null,
                    hideFields: true,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/AddLearnerMathScienceSupport',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    MathScienceSupportID: this.state.selectedLearnerMathScienceSupport[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/AddLearnerMathScienceSupport',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    MathScienceSupportID: this.state.selectedLearnerMathScienceSupport[0].Id,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem,
                    viewOnly: true
                }
            })
        }


    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerMathScienceSupport: selection })
        } else {
            this.setState({ selectedLearnerMathScienceSupport: null })
            toolbarRef.current.setButtonDisabled("edit", true)
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Math and Science Support > ${this.props.location.state.learnerNames}`}
                </p>
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/learnerMathScienceSupport/GetLearnerMathScienceSupportSearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/learnerMathScienceSupport/unlinkLearnerMathScienceSupport"} useDeletePost={true}
                    entityViewPath='/AddLearnerMathScienceSupport'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="ProgrammeTitle" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Learner Math and Science Support"}
                    promptContent={"Are you sure you want to remove the learner Math and Science Support record?"}
                >
                    <MathScienceSupportSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerMathScienceSupport));

