import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "../../ViewUtils";

const utils = new ViewUtils();
export const CareerGuidanceWorkshopForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} values={props.data} viewId="AddCareerGuidanceWorkshop" dataURL="api/sims/CareerGuidance/GetFind"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="CareerGuidanceWorkshopForm">
                    <FormTabContainer>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/CareerGuidance/GetLookupList?listId=FinancialYear"}
                                                id="FinancialYearID"
                                                label="Financial Year"
                                                form={form}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/CareerGuidance/GetLookupList?listId=Project"}
                                                id="ProjectID"
                                                label="Project"
                                                form={form}
                                                className="w-100"
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NameOfEvent"
                                                label="Name of event"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfEvents"
                                                label="Number Of Events"
                                                className="w-100"
                                                required={true}
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="Number is required"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                key={tabValue}
                                                label="Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                key={tabValue}
                                                label="End Date"
                                                className='{classes.textField} w-100 '
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.StartDate)
                                                            && formValues.StartDate.getTime() < formValues.EndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/CareerGuidance/GetLookupList?listId=Province"
                                                id="ProvinceID"
                                                key={tabValue}
                                                label="Province"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/CareerGuidance/GetLookupList?listId=Municipality"
                                                id="MunicipalityID"
                                                key={tabValue}
                                                label="Municipality"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="MunicipalityArea"
                                                label="Municipality Area"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/CareerGuidance/GetLookupList?listId=UrbanRural"
                                                id="UrbanRuralID"
                                                key={tabValue}
                                                label="Urban or Rural?"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/CareerGuidance/GetLookupList?listId=CareerGuidanceSponsorship"
                                            id="SponsorshipID"
                                            key={tabValue}
                                            label="Sponsorship"
                                            className="w-100"
                                            form={form}
                                            required
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AmountSpent"
                                                label="Amount Spent"
                                                className="w-100"
                                                required={true}
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="Number is required"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfCareerGuidesDistributed"
                                                label="Number Of Career Guides Distributed"
                                                className="w-100"
                                                required={true}
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="Number is required"
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAgreementSigned"
                                                key={tabValue}
                                                label="Date Agreement Signed"
                                                className='{classes.textField} w-100'
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100'
                                                disabled
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
