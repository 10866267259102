import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EnhancedToolbar from './Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ViewUtils from '../ViewUtils';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from '../LoadingIndicator';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';


const utils = new ViewUtils();
const CustomSwitch = withStyles({
    switchBase: {
        color: "#007f3d",
      '&$checked': {
          color: "#007f3d"
      },
      '&$checked + $track': {
        backgroundColor:"#808080",
      },
    },
    checked: {},
    track: {},
  })(Switch);

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {
                    props.multiSelect === true ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </TableCell> : <TableCell />

                }
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeaderCell}
                        width={typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCells.length) + "%"}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell className={classes.scrollPadding}>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        height: "100%",
        marginBottom: theme.spacing(2),
    },
    scrollPadding: {
        padding: '8px !important'
    },
    noScrollPadding: {
        padding: '0 !important',
        width: '1px !important'
    },
    tableBody: {
        display: 'block',
        height: 'calc(100% - 64px)',
        overflowY: 'auto'
    },
    tableHeaderCell: {
        overflow: 'inherit'
    },
    tableHead: {
        display: 'block',
        height: '64px',
        overflow: 'inherit'
    },
    tableWrapper: {
        height: '100%'
    },
    table: {
        height: '100%'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

export default function EnhancedTable(props) {
    const utils = new ViewUtils();
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [hasVScroll, setHasVScroll] = React.useState(true);
    const [dense, setDense] = React.useState(true);
    const [fetchEnabled, setFetchEnabled] = React.useState(utils.isNull(props.autoFetchData) || props.autoFetchData === true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { headCells } = props;
    const { actionsToolbar } = props;
    const [searchParameters, setSearchParameters] = React.useState(props.searchParameters);

    React.useEffect(() => {
        if (fetchEnabled && !utils.isNull(props.searchParameters)) {
            setSearchParameters(props.searchParameters);
            utils.invokeUrl(props.dataUrl + "?request=" + utils.doGetRequestJson(page, rowsPerPage, props.searchParameters, props.paged), (data) => {

                var result;
                if (typeof data === "string") {
                    result = JSON.parse(data);
                } else {
                    result = data;
                }

                setRows(result.Data);
                setTotalNumberOfRows(result.TotalNumberOfRows);

                if (!utils.isNull(props.onDataArrive)) {
                    props.onDataArrive(result);
                }

                updateScrollBarPadding();
            }, null);
            trackPromise(
                fetch()
                    .then(res => res.json())
                    .then((data) => {
                    }));
        }

        setFetchEnabled(true);
    }, [props.searchParameters]);

    function updateScrollBarPadding() {
        var tbody = document.getElementById('mainTableBody');
        if (tbody !== null && typeof tbody !== 'undefined') {
            setHasVScroll(tbody.scrollHeight > tbody.clientHeight);
        }
    }

    function getRequestJson(currentPage, curretRowsPerPage, paged) {
        return utils.doGetRequestJson(currentPage, curretRowsPerPage, searchParameters, paged);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.name);
            setSelected(newSelecteds);
            props.onSelectionChange(newSelecteds);
            return;
        }

        setSelected([]);
        props.onSelectionChange([]);
    }

    function doHandleClick(event, id) {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (utils.isNull(id)) {
            console.warn("invalid state - NULL row ID");
            return;
        }

        if (props.multiSelect === true) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        } else {
            newSelected.push(id);
        }

        let selectedRows = [];
        for (var i = 0; i < rows.length; i++) {
            let row = rows[i];
            for (var j = 0; j < newSelected.length; j++) {
                if (row.Id === newSelected[j]) {
                    selectedRows.push(row);
                }
            }
        }

        setSelected(newSelected);
        if (!utils.isNull(props.onSelectionChange)) {
            props.onSelectionChange(selectedRows);
        }
    }

    function handleClick(event, id) {
        if (!utils.isNull(props.onCellSelectionChange)) {
            return;
        }

        doHandleClick(event, id);
    }

    function handleCellClick(event, id, headCell) {
        if (headCell.contentType !== "document") {
            // TODO : Evaluate if this call is necessary
            doHandleClick(event, id);
        }

        if (!utils.isNull(props.onCellSelectionChange)) {
            props.onCellSelectionChange(id, headCell);
        }
    }

    function handleChangePage(event, newPage) {
        utils.invokeUrl(props.dataUrl + "?request=" + getRequestJson(newPage, rowsPerPage, true), (data) => {
            setRows(data.Data);
            setPage(newPage);

            if (!utils.isNull(props.onDataArrive)) {
                props.onDataArrive(data);
            }

            updateScrollBarPadding();
        }, null);
    }

    function handleChangeRowsPerPage(event) {
        var curretRowsPerPage = + event.target.value;
        utils.invokeUrl(props.dataUrl + "?request=" + getRequestJson(0, curretRowsPerPage, true), (result) => {
            setRows(result.Data);
            setRowsPerPage(curretRowsPerPage);
            setPage(0);

            if (!utils.isNull(props.onDataArrive)) {
                props.onDataArrive(result);
            }

            updateScrollBarPadding();
        }, null);
    }

    function handleChangeDense(event) {
        setDense(event.target.checked);
        updateScrollBarPadding();
    }
    
    function handleCellValueChange(value, row, column) {
        let editedRow = null;

        for (let i = 0; i < rows.length; i++) {
            if (row === rows[i].Id) {
                editedRow = rows[i];
            }
        }

        if (editedRow !== null) {
            editedRow[column] = value;

            if (!utils.isNull(props.cellValueChangeHandler)) {
                props.cellValueChangeHandler(editedRow, column);
            }
        }
    }

    const isSelected = id => selected.indexOf(id) !== -1;

    return (
        <div style={{ height: "inherit" }}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={totalNumberOfRows}
                            headCells={headCells}
                            multiSelect={props.multiSelect}
                        />
                        <TableBody className={classes.tableBody} id="mainTableBody">
                            <LoadingIndicator />
                            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row.Id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                let counter = 0;

                                return (
                                    <TableRow
                                        hover
                                        onClick={event => handleClick(event, row.Id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.Id}
                                        selected={isItemSelected}
                                    >
                                        {
                                            props.multiSelect === true ?
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> : <TableCell />

                                        }
                                        {headCells.map(headCell => (
                                            counter++ === headCells.length - 1 && actionsToolbar !== null && typeof actionsToolbar !== "undefined" ?
                                                <TableCell
                                                    width={typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCells.length) + "%"}
                                                    align="left"
                                                    key={headCell.id}>
                                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => props.buttonClickHandler(buttonId)} config={actionsToolbar} />
                                                </TableCell> :
                                                headCell.numeric === true ?
                                                    <TableCell
                                                        width={typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCells.length) + "%"}
                                                        align="right" onClick={event => handleCellClick(event, row.Id, headCell)}
                                                        key={headCell.id}>
                                                        <TableCellContent selected={isItemSelected} row={row.id} column={headCell.id} cellChangeHandler={handleCellValueChange}
                                                            contentValue={row[headCell.id]} contentType={props.contentType} editor={headCell.editor} />
                                                    </TableCell> :
                                                    <TableCell
                                                        width={typeof headCell.widthRatio !== 'undefined' ? (100 * headCell.widthRatio) + "%" : (100 / headCells.length) + "%"}
                                                        align="left" onClick={event => handleCellClick(event, row.Id, headCell)}
                                                        key={headCell.id}>
                                                        <TableCellContent selected={isItemSelected} row={row.Id} column={headCell.id} cellChangeHandler={handleCellValueChange}
                                                            contentValue={row[headCell.id]} contentType={headCell.contentType} editor={headCell.editor} />
                                                    </TableCell>

                                        ))}

                                        <TableCell className={hasVScroll ? classes.noScrollPadding : classes.scrollPadding}>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                {props.paged === false ? "" : <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalNumberOfRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />}

            </Paper>
            <FormControlLabel
                control={<CustomSwitch checked={dense} onChange={handleChangeDense} />}
                label="Dense"
            />
        </div>
    );
}

class TableCellContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentValue: props.contentValue,
            contentType: props.contentType,
            className: props.className,
            options: [],
            optionsLoaded: false
        }
    }

    getFormattedDate = (date) => {
        let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let year = date.getFullYear();

        return year + '/' + month + '/' + day;
    }

    handleDateChange = date => {
        this.setState({ contentValue: this.getFormattedDate(date) });
        this.props.cellChangeHandler(this.getFormattedDate(date), this.props.row, this.props.column);
    };

    handleValueChange(event) {
        this.setState({ contentValue: event.target.value });
        this.props.cellChangeHandler(event.target.value, this.props.row, this.props.column);
    };
    
    handleSelectValueChange(event) {
        let objectReferenceValue = null;
        let value = event.target.value;

        if (!utils.isNull(this.state.options)) {
            for (let i = 0; i < this.state.options.length; i++) {
                if (value === this.state.options[i].Id) {
                    objectReferenceValue = this.state.options[i];
                }
            }
        }

        this.setState({ contentValue: objectReferenceValue });
        this.props.cellChangeHandler(objectReferenceValue, this.props.row, this.props.column);
    };

    componentDidMount() {
        if (!utils.isNull(this.props.editor) && !utils.isNull(this.props.editor.dataUrl)
            && !this.state.optionsLoaded) {
            utils.invokeUrl(this.props.editor.dataUrl, (data) => {
                this.setState({ options: data });
            }, null);

            this.setState({ optionsLoaded: true });
        }
    }

    render() {
        let displayValue;

        const linkStyle = {
            cursor: 'pointer',
            textDecoration: 'underline'
        }

        if (!utils.isNull(this.props.editor) && this.props.selected) {
            if (this.props.editor.type === "TEXT") {
                displayValue =
                    <TextField
                        id={"textEditor" + this.props.row}
                        style={{ width: "100%" }}
                        value={this.state.contentValue || ''}
                        onChange={(event) => this.handleValueChange(event)}
                        margin="normal"
                        variant="outlined">
                    </TextField>
            } else if (this.props.editor.type === "DATE") {
                displayValue =
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            id={"dateEditor" + this.props.row}
                            format="yyyy/MM/dd"
                            value={this.state.contentValue}
                            formatDate={(date) => {this.getFormattedDate()}}
                            onChange={this.handleDateChange}
                            inputVariant="outlined"
                            size="small"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{minWidth: "170px"}}
                        />
                    </Grid>
                    </MuiPickersUtilsProvider>
            } else if (this.props.editor.type === "SELECT") {
                displayValue =
                    <TextField
                        select
                        style={{ width: "100%" }}
                        id={"selectEditor" + this.props.row}
                        value={!utils.isNull(this.state.contentValue) ? this.state.contentValue.Id : null}
                        onChange={(event) => this.handleSelectValueChange(event)}
                        margin="normal"
                        variant="outlined">
                        {
                            this.props.editor.allowEmptyValue === true ?
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> : <span />
                        }
                        {this.state.options.map(option => (
                            <MenuItem key={option.Id} value={option.Id}>{utils.isNull(option.Description) ? "" : option.Description.trim()}</MenuItem>
                        ))}
                    </TextField>
            }
        } else {
            if (this.state.contentType !== 'undefined' && this.state.contentType == 'document') {
                displayValue = <span style={linkStyle}>{this.state.contentValue}</span>
            }
            else {
                displayValue = this.state.contentValue;

                if (!utils.isNull(displayValue) && typeof displayValue === 'object') {
                    displayValue = displayValue.Description;
                }
            }
        }

        return utils.isNull(displayValue) || displayValue.length === 0 ? "" : displayValue;
    }
}