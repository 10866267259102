import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import { LookupField } from '../../../widgets/LookupField';
import InstitutionSearch from '../../program/InstitutionSearch';
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerWILForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    
    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('InformationVerified', event.target.checked);
    };
    
    const handleReplacementChange2 = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('QualificationCompleted', event.target.checked);
    };

    return (
        <EntityForm ref={ref} values={props.data} viewId="WILSetup" dataURL="api/sims/learnerwil/GetLearnerLinkedWILDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerWILForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="WIL Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerwil/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnerwil/GetLookupList?listId=WILProgramme"
                                                id="WILProgrammeID"
                                                parentId={0}
                                                key={tabValue}
                                                label="WIL Programme"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                key={tabValue}
                                                label="Actual Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                key={tabValue}
                                                label="End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerwil/GetProjectByInterventionType"
                                                id="ProjectID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerwil/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="EmployerSkillsLevy"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Employer Skills Levy"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="HostEmployer"
                                                parentId={0}
                                                key={tabValue}
                                                label="Host Employer"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerwil/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("QualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                parentId={0}
                                                required
                                                label="Institution"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerwil/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value);
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerwil/GetLookupList?listId=SETA"
                                                id="SETAID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="SETA"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAgreementSigned"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Date Agreement Signed"
                                                className='{classes.textField} w-100 '
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="LastSignatureDate"
                                                key={tabValue}
                                                label="Last Signature Date"
                                                className='{classes.textField} w-100 '
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="LearnerAgreementNumber"
                                                key={tabValue}
                                                label="Learner Agreement Number"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerwil/GetLookupList?listId=WILStatus"
                                                        id="WILStatusID"
                                                        parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        required
                                                        key={tabValue}
                                                        label="WIL Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerwil/GetDurationByInterventionType"
                                                id="DurationID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Duration"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CostPerLearner"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Cost Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AmountSpentPerLearner"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Amount Spent Per Learner"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>

                                    </tr>
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="QualificationCompleted"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                onChange={handleReplacementChange2}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Qualification Completed"
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                key={tabValue}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                            <td xs lg="6">
                                                <CustomCheckbox
                                                        id="InformationVerified"
                                                        key={tabValue}
                                                        form={form}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        onChange={handleReplacementChange}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                        label="Information Verified"
                                                    />
                                            </td>
                                                <td>
                                                    <CustomTextField
                                                        id="Verifier"
                                                        key={tabValue}
                                                        label="Verifier"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="VerifiedDate"
                                                        key={tabValue}
                                                        disabled
                                                        label="Date Verified"
                                                        className='{classes.textField} w-100'
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                </tr>
                                                :""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
