import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Employer.css";
import EmployerSearchForm from './EmployerSearchForm';
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import LinkDialog from "../LinkDialog";
import UserSearch from "../UserSearch";
import withMenuPermission from "../../widgets/withMenuPermission";


const utils = new ViewUtils();
const toolbarRef = React.createRef();

const headCells = [
    { id: 'OrganisationLegalName', numeric: true, disablePadding: false, label: 'Employer Legal Name', widthRatio: .5 },
    { id: 'OrganisationSDLNumber', numeric: false, disablePadding: false, label: 'Employer SDL Number', widthRatio: .25 },
    {
        id: 'OrganisationTradeName', numeric: false, disablePadding: false, label: 'Employer Trade Name', widthRatio: .25,
    }
];



const filterParameters = [
    { dataField: "OrganisationTradeName", text: "OrganisationTradeName" },
    { dataField: "OrganisationSDLNumber", text: "OrganisationSDLNumber" },
    { dataField: "OrganisationLegalName", text: "OrganisationLegalName" }

]

class EmployerSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                OrganisationTradeName: "",
                OrganisationSDLNumber: "",
                OrganisationLegalName: ""
            },
            parameters: {},
            keyUpSwitch: false,
            selectedEmployer: null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'viewemp', label: '', tooltip: 'View', icon: 'ViewIcon', disabled: true, visible: this.props.menuPermissionItem.View },
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'delete', label: '', tooltip: 'Delete Organisation', icon: 'DeleteIcon', disabled: true, visible: this.props.menuPermissionItem.Delete }
                    ]
                }
            ]
        };

        this.searchParameters = this.searchParameters = !utils.isNull(this.props.programmeId) ?

            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "OrganisationTradeName", Value: "" },
                { Name: "OrganisationSDLNumber", Value: "" },
                { Name: "OrganisationLegalName", Value: "" }
            ]
            :
            [
                { Name: "OrganisationTradeName", Value: "" },
                { Name: "OrganisationSDLNumber", Value: "" },
                { Name: "OrganisationLegalName", Value: "" }
            ]
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedEmployer: selection })
            if (this.props.mode !== "lookup") {
                toolbarRef.current.setButtonDisabled("viewemp", false);
            }
        } else {
            this.setState({ selectedEmployer: null })
            if (this.props.mode !== "lookup") {
                toolbarRef.current.setButtonDisabled("viewemp", true);
            }
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("add" === buttonId) {
            this.setState({ linkDialogOpen: true })
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: {
                    selection: this.state.selectedEmployer[0].Id,
                    viewOnly: true
                }
            })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/employer/getsearch"}
                    entityViewPath='/employer'
                    model={this.state.model}
                    title='Employer'
                    toolbarConfig={this.toolbarConfig}
                    toolbarRef={toolbarRef}
                    searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="OrganisationLegalName"
                    mode={this.props.mode}
                    history={this.props.history}
                    hasButtonClickHandler={true}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/employer/removeemployer"} useDeletePost={true}
                    promptTitle={"Remove Organisation"} promptContent={"Are you sure you want to remove this organisation?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <EmployerSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />
                </SearchView>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={""}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Add Employer`}
                    linkName={"Users"} postUrl={"api/indicium/employer/linkUsers"}

                >
                    {/*+27635097174 27631817067*/}
                    <UserSearch
                        multiSelect={true} showTitle={false}
                        mode="lookup"
                        dataUrl="api/indicium/employer/searchLinkableUsers"
                    />
                </LinkDialog>
            </ViewContainer>

        );
    }
}


export default withRouter(withMenuPermission(EmployerSearch));

