import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { Button } from "react-bootstrap";
//import { actionCreators } from '../../store/SecurityStore';
import * as actions from '../../store/actions/auth';
import "./RoleSelect.css";
import { withAlert } from 'react-alert';
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";
import simslogoLoading from '../../content/images/logos/hwseta_logo.jpg'

//const handler = (state) => {
//    return {
//        userRoles: state.security.userRoles,
//        userDetails: state.security.userDetails,
//        isLoading: state.security.isLoading,
//        roleSet: state.security.roleSet,
//        error: state.security.error
//    };
//};

const utils = new ViewUtils();

class RoleSelect extends Component {

    state = {
        selectedRole: "-1",
        message: "",
        loading: true
    };

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
                //console.log('open')
            }, // callback that will be executed after this alert open
            onClose: () => {
                //console.log('closed')
            } // callback that will be executed after this alert is removed
        })
    }

    //validateForm() {
    //    return this.state.selectedRole !== null;
    //}

    componentDidMount() {
        if (!utils.isNull(this.props.userDetails) && !utils.isNull(this.props.userDetails.UserKey)) {
            this.props.onGetRoles(this.props.userDetails.UserKey);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roleSet !== this.props.roleSet && this.props.roleSet) {
            clearTimeout(this.props.userLoginTimer);
            this.props.onGetMenuPermissions();
            //this.props.history.push('/home');
        }

        if (prevProps.menuPermissions !== this.props.menuPermissions && this.props.menuPermissions) {
            //this.props.history.push('/dashboard');
            this.props.history.push(this.props.dashboardURL);
        }

        if (prevProps.loginRedirectToLogin !== this.props.loginRedirectToLogin && this.props.loginRedirectToLogin) {
            //this.props.history.push('/login');
            this.props.history.push(this.props.loginRedirectToLoginPath);
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }

        if (prevProps.IsProvider !== this.props.IsProvider && this.props.IsProvider) {
            this.props.history.push('/loginprovider');
        }

        if (prevProps.IsEmployer !== this.props.IsEmployer && this.props.IsEmployer) {
            this.props.history.push('/loginemployer');
        }

        if (prevProps.userKeyRetrieved !== this.props.userKeyRetrieved && this.props.userKeyRetrieved) {
            this.props.onGetRoles(this.props.userDetails.UserKey);
        }
    }

    back() {
        clearTimeout(this.props.userLoginTimer);
        //this.props.history.push('/login');
        this.props.history.push(this.props.loginRedirectToLoginPath);
    }

    select() {
        this.setState({ message: "" });
        //this.props.selectRole(this.state.selectedRole, this.props.userDetails.Username);
        this.props.onSelectRoles(this.props.userDetails.UserKey, this.state.selectedRole);
    }

    render() {
        return (
            <div className="role-container">
                <img src={simslogoLoading} className="logo-bottom-left" alt="logo" />
                <div className="RoleSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Select a Role</p>
                    <form className="style-2" id="scroll">
                        {this.props.userRoles.map(userRole => (
                            <div className="form-check" key={userRole.Id}>
                                <label>
                                    <input type="radio"
                                        value={userRole.Id}
                                        checked={this.state.selectedRole === userRole.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedRole: ev.target.value })}
                                    />
                                    <span className="label-text"> {userRole.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="role-btn"
                                        disabled={this.state.selectedRole === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}

//export default withRouter(
//    connect(
//        handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(RoleSelect)
//);

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        userRoles: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Roles) ? state.auth.userDetails.Roles : [] : [],
        loginErrorMessage: state.auth.errorMessage,
        loginRedirectToLogin: state.auth.redirectToLogin,
        userLoginTimer: state.auth.userLoginTimer,
        roleSet: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Token) ? true : false : false,
        menuPermissions: !utils.isNull(state.auth.menuPermissionsDetails) ? true : false,
        loginRedirectToLoginPath: state.auth.loginRedirectToLoginPath,
        IsProvider: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.IsProvider : false,
        IsEmployer: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.IsEmployer : false,
        dashboardURL: !utils.isNull(state.auth.menuPermissionsDetails) ? state.auth.menuPermissionsDetails.DashboardInfo.DashboardURL : null,
        userKeyRetrieved: !utils.isNull(state.auth.userDetails) && !utils.isNull(state.auth.userDetails.UserKey) ? true : false
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRoles: (UserKey) => dispatch(actions.authRoleGet(UserKey)),
        onSelectRoles: (UserKey, RoleKey) => dispatch(actions.authRoleSelect(UserKey, RoleKey)),
        onGetMenuPermissions: () => dispatch(actions.authMenuPermission())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(RoleSelect)));
