import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import InstitutionSearch from '../../program/InstitutionSearch';
import OFOSearch from '../../program/OFOSearch';
import ViewUtils from "../../../ViewUtils";
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const LearnerCandidacyForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    //const handleReplacementChange = form => event => {
    //    if (!event.target.checked) {
    //    }

    //    form.updateValue('IsVerified', event.target.checked);
    //};

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }


    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "HWSETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('IsVerified', event.target.checked);
    };

    function professionalBodyCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }



    return (
        <EntityForm ref={ref} values={props.data} viewId="CandidacySetup" dataURL="api/sims/learnercandidacy/GetLearnerLinkedCandidacyDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerCandidacyForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Candidacy Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Candidacy Placement" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnercandidacy/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="OFOOccupationID"
                                                label="OFO Occupation"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnercandidacy/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Sponsorship"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=Project"
                                                id="ProjectID"
                                                parentId={0}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                key={tabValue}
                                                label="Project"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ServiceLevelAgreementNumber"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Learner Agreement Number"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FundingAgreementNumber"
                                                key={tabValue}
                                                label="Funding Agreement Number"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CumulativeSpent"
                                                validationRegex={/^[0-9]+$/}
                                                validationMessage="This field must be numeric"
                                                parentId={1}
                                                required
                                                label="Cumulative Spent"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=YearOfStudy"
                                                id="YearOfStudyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Year Of Study"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form} 
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsIndustrySpecific"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form} 
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Specific?"
                                            />
                                        </td>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IndustryNonSpecific"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Industry Non Specific?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=NQFLevel"
                                                id="NQFLevelID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="NQF Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        {/*<td>*/}
                                        {/*    <CustomTextField*/}
                                        {/*        id="AmountSpent"*/}
                                        {/*        key={tabValue}*/}
                                        {/*        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}*/}
                                        {/*        label="Amount Spent"*/}
                                        {/*        className="w-100 "*/}
                                        {/*        required*/}
                                        {/*        InputProps={{*/}
                                        {/*            readOnly: false,*/}
                                        {/*        }}*/}
                                        {/*        form={form} */}
                                        {/*    />*/}
                                        {/*</td>*/}
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupListCandidacyProfessionalBody?id=1"
                                                id="ProfessionalBodyID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Professional Body"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="OtherSponsorshipReason"
                                                key={tabValue}
                                                label="Other (reason)"
                                                className="w-100 "
                                                required={professionalBodyCheck(form.values["ProfessionalBodyID"])}
                                                disabled={!professionalBodyCheck(form.values["ProfessionalBodyID"])}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupListCandidacyHEL?id=1"
                                                id="HighestEducationLevelID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Highest Education Level"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=PlacementStatus"
                                                id="PlacementStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Placement Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="InstitutionID"
                                                label="FET"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <InstitutionSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/GetInstitutionSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("InstitutionID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="HET"
                                                key={tabValue}
                                                label="HET"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="End Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="EstimateCompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Estimate Date of Completion"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="CandidQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnercandidacy/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("CandidQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=PublicPrivate"
                                                id="PublicPrivateID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Is the training provider Public/Private?"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td >
                                            <SelectItem
                                                dataUrl="api/sims/learnercandidacy/GetLookupList?listId=GraduateIntern"
                                                id="GraduateInternID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Graduate / Intern"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Most Recent Registration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="IsVerified"
                                                key={tabValue}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                onChange={handleReplacementChange}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Information Verified"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="VerifiedDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled
                                                label="Verification Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Verifier"
                                                key={tabValue}
                                                label="Verifier"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl={'api/sims/learnercandidacy/GetProgrammeStatusByRole?parameter=[{"Name":"CandidacyStatusID","Value":' + GetProgrammeStatusID(form.values["CandidacyStatusID"]) + '}]'}
                                                        id="CandidacyStatusID"
                                                        parentId={0}
                                                        required
                                                        key={tabValue}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        label="Candidacy Status"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <tr>
                                                    <td>
                                                        <CustomTextField
                                                            id="ContractNumber"
                                                            key={tabValue}
                                                            label="Contract Number"
                                                            className="w-100 "
                                                            disabled
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            form={form}
                                                        />
                                                    </td>
                                                </tr>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnercandidacy/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["CandidacyStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["CandidacyStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["CandidacyStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminatedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Actual Termination Date"
                                                        required={TerminationCheck(form.values["CandidacyStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["CandidacyStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnercandidacy/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        className="{classes.select} w-100"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="CreatedBy"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="LearnerTracking"
                                                        key={tabValue}
                                                        label="Learner Tracking"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CompletionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        disabled
                                                        label="Completion Date"
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <tr>
                                <td>
                                    <LookupField
                                        id="PlacementEmployerID"
                                        label="Employer"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        required
                                        className="w-100"
                                        form={form}
                                    >
                                        <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnercandidacy/EmployerSearch"
                                            onLookupValueChange={(value) => {
                                                form.setLookupSelection("PlacementEmployerID", value)
                                            }} autoFetchData={false} selection={props.selection} />
                                    </LookupField>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <GPSCoordinatesAPI
                                        id="Address"
                                        key={tabValue}
                                        className="w-100"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Address"
                                        form={form}
                                        updateGPSAddress={updateGPSAddress}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress1"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        label="Employer Physical Address 1"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress2"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 2"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress3"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        required
                                        label="Employer Physical Address 3"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <AddressCodeItem
                                        id="PhysicalCode"
                                        addressId="Physical"
                                        value={physicalCodeValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        key={tabValue}
                                        provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                        municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                        districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                        urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                        suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                        cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                        className="w-100"
                                        label="Employer Physical Code"
                                        hasMunicipalityID={true}
                                        hasProvinceID={true}
                                        hasCityID={true}
                                        hasDistrictID={false}
                                        hasUrbanRuralID={true}
                                        hasSuburbID={true}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentStartDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Placement Start Date"
                                        required
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentEndDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Placement End Date"
                                        required
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <SelectItem
                                        dataUrl="api/sims/learnercandidacy/GetLookupList?listId=TypeOfPlacement"
                                        id="TypeOfPlacementID"
                                        parentId={0}
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Type Of Placement"
                                        required
                                        className="{classes.select} w-100"
                                        allowEmptyValue={true}
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="EmployerContractNumber"
                                        key={tabValue}
                                        label="Employer Contract Number"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        className="w-100 "
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
