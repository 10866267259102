
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import AlertItem from "../../widgets/AlertItem";
import "./../organisation/DGOrganisation.css";
import DGApplicationSearchForm from "./DGApplicationSearchForm";
import DGApplicationCreationForm from "./DGApplicationCreationForm";
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "../../View";
import FormDialog from "../../etqa/FormDialog";
import Prompt from '../../widgets/Prompt';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import DGPrintReport from './DGPrintReport';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from "antd";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withMenuPermission from "../../widgets/withMenuPermission";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
    { id: 'FinancialYear', numeric: false, disablePadding: true, label: 'Financial Year' },
    { id: 'FundingWindow', numeric: false, disablePadding: true, label: 'Funding Window' },
    { id: 'SubmissionNumber', numeric: false, disablePadding: true, label: 'Submission Number' },
    { id: 'DGType', numeric: false, disablePadding: true, label: 'DG Type' },
    { id: 'DGIntervention', numeric: false, disablePadding: true, label: 'DG Intervention' },
    { id: 'DGStatus', numeric: false, disablePadding: true, label: 'DG Status' },
    { id: 'DueDate', numeric: false, disablePadding: true, label: 'Due Date' },
    { id: 'SubmittedDate', numeric: false, disablePadding: true, label: 'Submitted Date' },
    { id: 'SubmittedBy', numeric: false, disablePadding: true, label: 'Submitted By' },
    { id: 'VerificationDate', numeric: false, disablePadding: true, label: 'Verification Date' },
    { id: 'Verified By', numeric: false, disablePadding: true, label: 'Verified By' },
    { id: 'ApprovedDate', numeric: false, disablePadding: true, label: 'Approved Date' },
    { id: 'RejectedDate', numeric: false, disablePadding: true, label: 'Rejected Date' }
];



const utils = new ViewUtils();
const addFormRef = React.createRef();
const toolbarRef = React.createRef();

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class DGApplicationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyUpSwitch: false,
            addDialogOpen: false,
            selectedDGApplication: null,
            FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null,
            id: this.props.location.state.organisationID,
            promptOpen: false,
            promptTitle: "Remove DG Application",
            promptContent: "Are you sure you want to remove this DG Application?",
            searchParameters: [{ Name: "OrganisationID", Value: this.props.location.state.organisationID }, { Name: "FinancialYearID", Value: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null }],
            dependency: null,
            model: {
                FinancialYearID: { Id: 25, Description: "01 April 2023 - 31 March 2024" }
            }
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: "CloseIcon" }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create Application', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'view', label: '', tooltip: 'View Application Forms', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedDGApplication) },
                        { id: 'remove', label: '', tooltip: 'Remove DG Application', disabled: utils.isNull(this.state.selectedDGApplication), icon: "Delete", visible: this.props.menuPermissionItem.Delete },
                        { id: "print", label: "Print Authorisation Pages", tooltip: "", disabled: true },

                    ]
                }
            ]
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/DGOrganisation',
                state: { selection: this.state.id }
            });
        }
        else if ("add" === buttonId) {
            this.setState({ addDialogOpen: true, Message: "" })
        }
        else if ("view" === buttonId) {
            this.props.history.push({
                pathname: "/DGForms",
                state: {
                    DGID: this.state.selectedDGApplication,
                    organisationID: this.state.id,
                    FinancialYearID: this.state.FinancialYearID
                }
            })

        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        } else if ("print" === buttonId) {
            this.setState({ OpenPrintDialog: true });
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                addDialogOpen: false,
                selectedDGApplication: null,
                Message: "Organisation DG Application successfully created",
                MessageStyle: "success",
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });

            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else if (cancel === true) {
            this.setState({
                addDialogOpen: false,
                selectedDGApplication: null,
                Message: ""
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
        else {
            this.setState({
                addDialogOpen: false,
                selectedDGApplication: null,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
            toolbarRef.current.setButtonDisabled("remove", true);
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }


    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (!utils.isNull(selection)) {
                this.setState({ selectedDGApplication: selection[0].Id }, () => {
                });
                this.GetDGPermissionCheck(selection[0].Id);
            }
        } else {
            this.setState({ selectedDGApplication: null });
        }
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("print", selection === null || selection.length === 0);
    }


    GetDGPermissionCheck = (Id) => {
        let url = "api/sims/DGApplication/GetDGApplicationPermissionCheck?DGApplicationID=" + Id;
        utils.invokeUrl(url, (response) => {
            if (response.Result.split(",")[0] === "ViewOnly") {
                this.setState({
                    adddisabled: true,
                })
                toolbarRef.current.setButtonDisabled("remove", true);
                toolbarRef.current.setButtonDisabled("view", true);
            }
            else if (response.Result.split(",")[1] === "Delete") {
                this.setState({ adddisabled: true }, () => {
                    toolbarRef.current.setButtonDisabled("remove", false);
                    toolbarRef.current.setButtonDisabled("view", false);
                })

            }
            else if (response.Result.split(",")[0] === "Enabled") {
                this.setState({ adddisabled: true }, () => {
                    toolbarRef.current.setButtonDisabled("remove", false);
                    toolbarRef.current.setButtonDisabled("view", false);
                })

            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successfulApplicationRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedDGApplication: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulApplicationRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeApplication(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedDGApplication}`;
        utils.invokeUrlDelete(fullUrl, this.successfulApplicationRemoveCallback, this.unsuccessfulApplicationRemoveCallback);
    }
    closePrompt = () => {
        this.setState({ promptOpen: false })
    }

    handleFormValueChange(values) {

        let FinancialYearID = values.FinancialYearID.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = FinancialYearID
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            FinancialYearID: FinancialYearID
        });
    }

    handleCreateFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }

        console.log(newValues);
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    clearGrid: true,
                    selectedDG: null,
                    Message: ""
                });
                if (!utils.isNull(newValues.DGTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(newValues.DGTypeID)) {
                    const dgTypeID = [{ Name: "FinancialYearID", Value: newValues.FinancialYearID.Id }, { Name: "DGTypeID", Value: newValues.DGTypeID.Id }]
                    if (!utils.isNull(dgTypeID[1].Value)) {
                        this.setState({ dependency: dgTypeID })
                    }
                };
            }
        }
    }

    ClosePrintDialog = () => {
        this.setState({ OpenPrintDialog: false });
        toolbarRef.current.setButtonDisabled("remove", true);
        toolbarRef.current.setButtonDisabled("view", true);
        toolbarRef.current.setButtonDisabled("print", true);
    };

    render() {
        const { classes } = this.props;
        return (
            <ViewContainer title="">

                <p className="breadcrumbs">
                    {"DG Application Dashboard" + (this.props.location.state.organisationName !== null ? " > " + this.props.location.state.organisationName + (this.props.location.state.sdlnumber !== null ? " - " + this.props.location.state.sdlnumber : "") : "")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <DGApplicationSearchForm
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    />

                    <EnhancedToolbar highlight={false}
                        title="WSP/ATR Document Uploads"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl={!utils.isNull(this.state.FinancialYearID) ? "api/sims/dgapplication/GetSearch" : null}
                        searchParameters={this.state.searchParameters}
                        paged={true} />
                </div>

                <FormDialog
                    open={this.state.addDialogOpen}
                    dialogTitle={"Create DG Application"}
                    viewRef={this}
                    entityInfo={{ OrganisationID: this.state.id }}
                    formRef={addFormRef}
                    saveUrl={"api/sims/DGApplication/InsertDGApplication"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                    valueChangeHandler={this.handleCreateFormValueChange}
                    datamodel={this.state.model}
                >
                    <DGApplicationCreationForm ref={addFormRef}
                        valueChangeHandler={this.handleCreateFormValueChange}
                        dependency={this.state.dependency}
                        data={this.state.model}
                    />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeApplication("api/sims/DGApplication/RemoveDGApplication") }}
                    closePrompt={this.closePrompt}
                />
                <Dialog fullScreen open={this.state.OpenPrintDialog} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.ClosePrintDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                Print DG Application Report
                            </Typography>
                        </Toolbar >
                    </AppBar >
                    <DGPrintReport DGApplicationID={this.state.selectedDGApplication} classes={classes} />
                </Dialog>

            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(DGApplicationDashboard)));
